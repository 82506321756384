import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog30/image1.png"
import image2 from "../../images/blog30/image2.jpg"
import image3 from "../../images/blog30/image3.jpg"
import image4 from "../../images/blog30/image4.png"
import image5 from "../../images/blog30/image5.jpg"
import image6 from "../../images/blog30/image6.jpg"
import image7 from "../../images/blog30/image7.jpg"
import image8 from "../../images/blog30/image8.jpg"






export default function HowBuffDoYouNeedToBeHealthy(){
    return(
        <BlogPostOld
            src={image8}
            title={"How Buff Do You Need to Be Healthy?"}
            summary={                "Having muscles is healthy. I would say that is a pretty accepted thought and not a lot of people would argue. But, do you need to be jacked like Arnold to reap the health benefits? No, absolutely not."}
            date={"Jan. 24, 2023"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}

        >
            <div>

                <p>Having muscles is healthy. I would say that is a pretty accepted thought and not a lot of people would argue. But, do you need to be jacked like Arnold to reap the health benefits? No, absolutely not.&nbsp;</p>
                <p>The reality is, we only need a fraction of what Arnold earned in his heyday. It isn’t that complicated nor as hardcore as what is marketed. Though there are many guidelines and targets that can be considered healthy, our go to reference is the World Health Organization’s (WHO) guidelines for physical activity.&nbsp;</p>
                <p>Their guidelines are listed above and are 150 min of cardio a week (think huffing and puffing - not just taking the dogs on a leisure walk) and 2x a week strength training of every major muscle group (again should be difficult - but we will get into it below).&nbsp;</p>
                <img src={image7} />
                <p>These aren’t perfect nor are they magic numbers that once achieved the clouds part and everything in your life becomes beautiful. However, we feel this target to become healthy are very reasonable and when we look at large scale population studies, those who adhere to these guidelines are far less likely to die of any disease. So if you like living, these guidelines are a good place to start.</p>
                <img src={image3} />
                <p>It is very practical to be able to huff and puff 20-30 min a day to add up to 150 min of cardio. In regards to resistance training, this is what builds muscle and this brings us back to our headline so I guess we should talk about it.&nbsp;</p>
                <p>Building muscles is crucial for long term health for EVERYBODY. Muscles are so underrated when it comes to health. It is far too common to see someone’s muscles waste away into adulthood as they slowly move less often and in less variety of ways. Listen, being an adult is hard enough, let’s not add physical limitations and/or poor health to the mix.</p>
                <p>Here is a nice list of why building muscles should be prioritized for everyone, not just meatheads. Believe me, your health depends on it (this list just scratches the surface - it’s wild).&nbsp;</p>
                <img src={image4} />
                <p>If we aren’t proactive, our muscle literally starts to waste away as we age. Starting in the 4th decade of life on average we are losing 8% of muscle mass (and this number increases per decade after this).&nbsp;</p>
                <img src={image} />
                <p>However, I have some good news. We can’t use age as an excuse because this is completely reversible - even in those who are in their 90’s and in nursing homes.&nbsp;</p>
                <img src={image2} />
                <p>Age is not a disease and we can biologically turn back the hands of time. So what do you have to do to blow out less candles on your birthday next year?</p>
                <p>This is where I wanted to dig a little deeper on the 2x a week resistance training that the WHO recommends. This recommendation states one should hit every muscle group twice a week and should be good. In general this is a fantastic start, super practical and doable for most.&nbsp;</p>
                <p>However, I think we have even more options. I think this because really smart people have provided us coaches and clinicians with a lot of data that is really encouraging and helps us teach people how they can become a healthier version of themselves.</p>
                <p>Here is a table I made from Brad Schoenfeld et al’s article that took every article they could find that has been published in their database and pooled together what it takes to build muscle.&nbsp;</p>
                <img src={image5} />
                <p>We know that just 4 sets per muscle group per week at any weight at any rep range (given it is 6-8/10 difficulty) will achieve muscle gain and increase strength. I think that last sentence is worth reading again.&nbsp;</p>
                <p>This is incredibly powerful. I want to break this down further and hopefully by the end, you are as stoked as I am about this (probably not but worth a try).&nbsp;</p>
                <p><span style={{textDecoration: 'underline'}}><strong>How often do I Need to Lift to Build muscle?</strong></span></p>
                <p>Doesn’t matter (especially for the beginner). Going off the above numbers as long as you hit a base of 4 sets per muscle group, you can break this up any way you want. If one long session a week works best for your schedule - do at least 4 sets per muscle group. If multiple short sessions work best for you then shoot you can do 1 set a day.&nbsp;</p>
                <p>One caveat here is that though research doesn’t strongly suggest this, I do believe higher frequency is likely to result in more muscle gain. Higher frequency is something I suggest for someone starting a new habit and/or someone who is trying to get jacked or get past a plateau.&nbsp;</p>
                <p><span style={{textDecoration: 'underline'}}><strong>How Many Reps Build Muscles.</strong></span></p>
                <p>Any rep range can build muscle. The important thing here is to get really close to failure if you’re doing light weight. For me lightweight is anything you can do 20 or more reps of. This would be bodyweight exercises, lighter dumbbells, a lot of group fitness classes, etc.&nbsp;</p>
                <img src={image6} />
                <p>When you are lifting objects that are heavier, you don’t need to be as close to failure to gain muscles. This is a bit of an art to find the right weight, especially as things get heavy (higher weight equals higher stakes so be smart). I like to start with “tough at ten” because it is time efficient and a nice, even number.&nbsp;</p>
                <p>Here is an easy guide to be smart; start at lightweight (again something you could do around 20 reps of) and warmup. Do 10 reps. Add weight in very small increments until it feels like you could do around 12-14 reps. This is your first set. Every set you should feel like you could do at least two more the target number (in this example at least two more than 10 so if my math is correct, 12 reps).&nbsp;</p>
                <p>This ensures that the weight is difficult enough to build muscle but doesn’t push you over the edge. This edge isn't the end of the world, we just haven’t found any additional benefit to train there to build muscle - so why do it? This above strategy equates to 6-8 out of 10 difficulty if we were to gauge it on a “rate of perceived exertion” scale like below. Don’t worry you won’t be quizzed on it, it is just another helpful tool to train with.&nbsp;</p>
                <p><span style={{textDecoration: 'underline'}}><strong>How Much Weight do I need to Lift to Build Muscle?</strong></span></p>
                <p>Though it is more time efficient to use heavier weights, one can build muscle using ANY weight range (even bodyweight). This often blows people’s minds when they hear this, which I enjoy.&nbsp;</p>
                <p>Since the weight range is so vast, I am going to narrow it down as Prof Schoenfeld and colleagues did in their study that is represented above in the table.&nbsp;</p>
                <p>Let’s say that “light weight” is anything you can do 20 reps or more of and “heavy weight” is anything you definitely could not do 20 reps or more of (or think of something tough at ten).&nbsp;</p>
                <p>Now that we got that covered, whether you are using light weight or heavy weight the key is how close you are to failure (basically needs to be pretty hard). With heavy weights, leave a couple in the tank. With light weights you can get closer to failure or even get to failure.&nbsp;</p>
                <p>At the end of the day we need to keep this simple. If we are to have an impact on our behaviors or those we are teaching, things need to be practical. Given what we know about building muscles we know that we do not have to be like Arnold hitting the gym for hours at a time.</p>
                <p>We can even do one hard set per muscle group a day if that works best. Or we can even do one long session per week and hit all of the minimum required 4 sets per muscle group if that works for your schedule. Let’s not make things harder than they need to be.&nbsp;</p>
            </div>

        </BlogPostOld>

    )
}